import axios from "axios";
const https = require('https');


// const agent = new https.Agent();
const agent = new https.Agent({ rejectUnauthorized: false });
const adapter = axios.create({ 
    baseURL: 'https://chatgroup-back.com/discord',
    // baseURL: 'http://localhost:3001',
    responseType: 'json',
    withCredentials: false,
    httpsAgent: agent
});


export default adapter;