import React from 'react';

import { useNavigate } from 'react-router-dom';
import {Col, Row, Image} from 'react-bootstrap';
import logo from '../../../assets/img/Vector.png';

const Header = ({activePage}) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const navigate = useNavigate();

    const handleLogout = () => {
      localStorage.clear(); 
      sessionStorage.clear();
      window.location.reload();
    };
    const headerStyle = {
        backgroundColor: '#202225',
        padding: '30px'
    };

    const headerLogo = {
        maxWidth: '100%',
        width: '50px',
        objectFit: 'cover',
    };
    const escBtn = {
      padding:'5px 25px',
      background:'#5865F2',
      color:'#fff',
      border:'none',
      borderRadius: '3px'
      
    }
    const userToBot = {
      display:'block',
      width:'15px',
      height:'15px',
      marginRight:'5px',
      background:'#2f6441ad',
    }
    const botToUser = {
      display:'block',
      width:'15px',
      height:'15px',
      marginRight:'5px',
      background:'#68682d85',
    }
    const botToBot = {
      display:'block',
      width:'15px',
      height:'15px',
      marginRight:'5px',
      background:'#53535193',
    }

    

  return (
    <Row className='justify-content-center align-items-center text-light' style={headerStyle}>
        <Col md={1}>
            <Image src={logo} style={headerLogo} />
        </Col>
        {activePage === 'discord'? (
        <>
          <Col md={2}><h5 className="m-0">Discord - Чаты</h5></Col>
          <Col md={5} className='d-flex'>
            <small>Вид обращения в чате:</small>
            <div className='mx-2 d-flex align-items-center'><span style={userToBot}></span><small> - user2bot</small></div>
            <div className='mx-2 d-flex align-items-center'><span style={botToUser}></span><small> - bot2user</small></div>
            <div className='mx-2 d-flex align-items-center'><span style={botToBot}></span><small> - bot2bot</small></div>
            
          </Col>
        </>
        ):
        activePage === 'viewChat'? (
          <>
            <Col md={5}><h5 className="m-0">Discord - Просмотр созданных диалогов</h5></Col>
            <Col md={2} className='d-flex'></Col>
          </>
        ):
        activePage === 'changeBots'? (
          <>
            <Col md={5}><h5 className="m-0">Discord - Добавить профиль бота</h5></Col>
            <Col md={2} className='d-flex'></Col>
          </>
        ):
        activePage === 'addBots'? (
          <>
            <Col md={5}><h5 className="m-0">Discord - Добавить нового бота</h5></Col>
            <Col md={2} className='d-flex'></Col>
          </>
        ):
        activePage === 'dialogsGenerator'? (
          <>
            <Col md={5}><h5 className="m-0">Discord - Сгенерировать диалог с помощью ИИ</h5></Col>
            <Col md={2} className='d-flex'></Col>
          </>
        ):
        activePage === 'chatsManagment'? (
          <>
            <Col md={5}><h5 className="m-0">Discord - Управление диалогами</h5></Col>
            <Col md={2} className='d-flex'></Col>
          </>
        ):
        (
          <>
            <Col md={3}><h5 className="m-0">Discord - Создать диалог</h5></Col>
            <Col md={4} className='d-flex'></Col>
          </>
        )}
        <Col md={2} className="d-flex justify-content-end text-decoration-underline">
          <em>Вы вошли как пользователь: <strong>{userData.name}</strong></em>
        </Col>
        <Col md={1} className="ms-4 d-flex justify-content-end">
          <button style={escBtn} onClick={handleLogout}>Выход</button>
        </Col>
    </Row>
  );
};

export default Header;