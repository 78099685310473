import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Button,Table } from 'react-bootstrap';
import './ChatsManagment.css';
import 'react-toastify/dist/ReactToastify.css';
import adapterLogic from '../../../adapterLogic';

const ChatsManagment = ({dialogJson}) => {
   
    const [data, setData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);

    const handleLaunchDialog = (id) => {
      adapterLogic.get('/curl.php', { params: { type: 'launch', channel: id } })
        .then(response => {
          setRefreshData(true);
          setTimeout(() => {
            const userData = JSON.parse(localStorage.getItem('userData'));
            if (userData.id) {
              handleGetDialogs(userData.id);
            }
          }, 5000);
        })
        .catch(error => {
          console.log(error);
        });
    };
    
    const handleStopDialog = (id) => {
      adapterLogic.get('/curl.php', { params: { type: 'stop', channel: id } })
        .then(response => {
          setRefreshData(true);
          setTimeout(() => {
            const userData = JSON.parse(localStorage.getItem('userData'));
            if (userData.id) {
              handleGetDialogs(userData.id);
            }
          }, 5000);
        })
        .catch(error => {
          console.log(error);
        });
    };
    
    const handleGetDialogs = (id) => {
      if (refreshData) {
        setRefreshData(false);
      }
      adapterLogic.get('/curl.php', { params: { type: 'all', user: id } })
        .then(response => {
          if (data) {
            setData(response.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
    };
    
    

      useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if(userData.id){
          handleGetDialogs(userData.id);
        }
      }, [refreshData]);
    return ( 
      <>
        
      <Table responsive className="managment-table">
        <thead>
          <tr>
            <th style={{ width: '5%' }}>#</th>
            <th style={{ width: '30%' }}>Название</th>
            <th style={{ width: '20%' }}>Статус</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td style={{ width: '5%' }}>{index + 1}</td>
              <td style={{ width: '20%' }}>{item.name}</td>
              <td style={{ width: '30%' }} className={item.state === 'stop' ? 'disabled': item.state === 'run'? 'enabled': ''}>{item.state}</td>
              <td className="d-flex justify-content-between" >
                <Button disabled={item.state !== 'stop'} className='mx-2' variant="success" onClick={()=>handleLaunchDialog(item.id)}>Запустить</Button>
                <Button disabled={item.state === 'stop'} className='mx-2' variant="danger" onClick={()=>handleStopDialog(item.id)}>Остановить</Button>
                {/* <Button className='mx-2' variant="info" onClick={()=>handleGetStatus(item.id)}>Обновить</Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </>
    );
}
 
export default ChatsManagment;





