import { useState } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
import './ResponsePanelGPT.css';
import adapter from "../../../../../adapter";

const ResponsePanelGPT = ({ replyId,  getAnswer, gptAnswer, sidebarSelectedElemId }) => {
  

  const [panelVisible, setPanelVisible] = useState(true);
  const [buttonState, setButtonState] = useState(false);
  const [inputShow, setInputShow] = useState(false);
  const [confirmedBtn, setConfirmedBtn] = useState(false);


  const [inputValue, setInputValue] = useState('');
  const [responseHide, setResponseHide ] = useState(false);

 
  

  const userData = JSON.parse(localStorage.getItem('userData'));


  const [reAskQuestion, setReAskQuestion] = useState(false);


  const handleButtonClick = (buttonName, answerId) => {
    if(buttonName === 'confirmed'){
      if (reAskQuestion) {
        if (window.confirm(`Вы уверены, что хотите переспросить "${inputValue}"?`)) {
          getAnswer(answerId, inputValue);
          setReAskQuestion(false);
          setButtonState(false);
          setInputShow(false);
          setConfirmedBtn(false);
          setInputValue('');
        }
      }else{
        if (window.confirm(`Подтверждаете отправку?`)) {
          let correctedQuestion;
          if (inputValue) {
            correctedQuestion = inputValue;
          }
          adapter.post('/confirm_question', {
            data:{
              answer:correctedQuestion? correctedQuestion: gptAnswer ,
              message_id: answerId,
              user_id:userData.id,
              channel_id:sidebarSelectedElemId
            }
          })
          .then((res) => {
            setResponseHide(true)
            setPanelVisible(false);
            setInputShow(false);
            setButtonState(false);
            setInputValue('');
          })
          .catch((error) => {
            console.error(error);
          });
          correctedQuestion = '';
        } 
      }
     
      
    }else if(buttonName === 'generate'){
      getAnswer(answerId);
    }else if(buttonName === 'fix'){
      setInputValue(gptAnswer);
      setButtonState(true);
      setInputShow(true);
      setConfirmedBtn(true);
    }else if (buttonName === 'again'){
      setButtonState(true);
      setInputShow(true);
      setConfirmedBtn(true);
    }else if(buttonName === 'cancel'){
        setButtonState(false);
        setInputShow(false);
        setConfirmedBtn(false);
        setInputValue('');
    }
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  
  return (
    <div>
      {
        gptAnswer && gptAnswer.length !== 0 && (
          <div className={`ms-5 my-2 `} hidden={responseHide}>
            <div className="response-from-gpt">
              <div className="fw-bold">Ответ ChatGPT:</div>
              <div>{gptAnswer}</div>
            </div>
          </div>
        )
      }
        
      {panelVisible && (
        <>
        {replyId && gptAnswer && gptAnswer.length === 0 ? (
          <div>
            <Row className={`mt-4 fade ${panelVisible ? "show" : ""}`}>
              <Col md={4}> 
                <Button
                  variant="light"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('generate', replyId)}
                >
                  Cгенерировать ответ
                </Button>
              </Col>
            </Row>
            {inputShow && (
              <Row
                className={`justify-content-between align-items-end mt-2 fade ${
                  inputShow ? "show" : ""
                }`}
              >
                <Form.Group controlId="formBasicText">
                  <Form.Label>Введите текст:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Введите текст"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>
            )}
        </div>
        ):(
          <div>
            <Row className={`mt-4 fade ${panelVisible ? "show" : ""}`}>
              <Col md={4}>
                <Button
                  variant="success"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('confirmed', replyId)}
                  disabled={buttonState}
                >
                  Принять
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="danger"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('fix')}
                  disabled={buttonState}
                >
                  Исправить
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="warning"
                  className="px-3 py-2 w-100"
                  style={{ color: "#36393f" }}
                  onClick={() => {
                    handleButtonClick('again', replyId);
                    setReAskQuestion(true);
                  }}
                  disabled={buttonState}
                >
                  Переспросить
                </Button>
              </Col>
            </Row>
            {inputShow && (
              <Row
                className={`justify-content-between align-items-end mt-2 fade ${
                  inputShow ? "show" : ""
                }`}
              >
                <Form.Group controlId="formBasicText">
                  <Form.Label>Введите текст:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Введите текст"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>
            )}
            {confirmedBtn && (
              <Row className={`mt-4 justify-content-center fade ${panelVisible ? "show" : ""}`}>
              <Col md={1}>
                <Button
                  variant="success"
                  className="px-3 py-2 w-20"
                  onClick={() => handleButtonClick('confirmed', replyId)}
                >
                  ✓
                </Button>
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  className="px-3 py-2 w-20"
                  onClick={() => handleButtonClick('cancel')}
                >
                  🗙
                </Button>
              </Col>
              </Row>
            )}
            
          </div>
        )}
        
        </>
      )}
    </div>
  );
};
export default ResponsePanelGPT;