import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card } from 'react-bootstrap';
import Alerter from '../alerter/Alerter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import adapter from '../../../adapter';
import './LoginPage.css';
import Loader from "../loader/Loader";

const LoginPage = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    password: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };


  const [loaderText, setLoaderText] = useState('');

  const handleSubmit = async () => {
    setLoaderText('Выполняем вход');
    try {
      const response = await adapter.post('/login', formData);
      
      if (response.data) {
        toast.success('Вход выполнен!');
        setTimeout(() => {
          window.location.reload();
          const userData = JSON.stringify(response.data.userData);
          localStorage.setItem('userData', userData);
          localStorage.setItem('authenticated', true);
          localStorage.setItem('accessToken', response.data.accessToken);
        }, 2000);
        setTimeout(() => {
          setLoaderText('');
        }, 2500);
      } else {
        setLoaderText('');
        toast.error('Ошибка!  ' + response.data.message);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoaderText('');
      console.error(error);
      toast.error('Ошибка!  ' + error);
    }
  };


  return (
    <>
    {loaderText !== ''? (
      <Loader loaderText={loaderText}/>
    ):(
    
    <div className="d-flex justify-content-center align-items-center vh-100 login-bg">
      <Card className="card mx-auto p-4 mt-3 login-form" bg="dark" text="light">
        <h2 className="text-center mb-4">Войти</h2>
        
          <Form>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter username"
              style={{ backgroundColor: '#1e1f22', color: 'white' }}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              autoComplete='off'
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              style={{ backgroundColor: '#1e1f22', color: 'white' }}
            />
          </Form.Group>

          <Button variant="primary" type="button" className="w-100 mt-5"  onClick={handleSubmit}>
            Войти
          </Button>
          </Form>
        
         
        
      </Card>
    </div>
    )}
    </>
  );
};

export default LoginPage;