import axios from "axios";
const https = require('https');


// const agent = new https.Agent({ rejectUnauthorized: false });
const adapterLogic = axios.create({ 
    baseURL: 'https://crazybot.net/groupchat/curl.php',
    responseType: 'json',
    withCredentials: false,
    // httpsAgent: agent
});


export default adapterLogic;